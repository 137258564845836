var memberTimer;
var page = {
	path 	: "",
	category: "",
	current	: "",
	next 	: "",
	id 		: "",
	beforePage: "",
	clickFlag: true,
	isFirst: true,
	init: function(){
		page.checkURL();
		page.nav();
		window.addEventListener('popstate', function (e) {
			$html.classList.add("disable_page");
			page.checkURL();
		});
		/*
		setInterval(function(){
			console.log(page.clickFlag);
		}, 100);
		*/
	},
	nav: function(){
		page.clickFlag = true;
		$("a.btn_close_detail").off().on("click", function(){
			let idx = $("#detail_content article").attr("data-id");
			
			$("#list_works li").each(function(i){
				if($(this).attr("id") === "works_" + idx){
					workIdx = i+1;
				}
			});
			//console.log(workIdx)
			page.next = "/?block=works";
			page.changeURL();
		});
		$("h1 a").off().on("click", function(e){
			e.preventDefault();
			page.next = $(this).attr("href");
			page.changeURL();
		});
		$("a.link_internal").off().on("click", function(e){
			e.preventDefault();
			page.next = $(this).attr("href");
			if(page.next === "/?block=works"){
				page.changeURL();
			}else{
				if( $(this).hasClass("active") ){ return false}
				page.changeURL();
			}
		});
		$("#list_works li a").off().on("click", function(e){
			e.preventDefault();
			let activeN 	= parseInt( $("#list_works li.active a").attr("data-num") );
			let currentN 	= parseInt( $(this).attr("data-num") );
			if( $(this).parent().hasClass("active") ){
				page.next = $(this).attr("href");
				page.changeURL();
			}else{
				if(currentN < activeN){
					wheel.dec();
				}else if(currentN > activeN){
					wheel.inc();
				}
			}
		});
		$("a#btn_next").off().on("click", function(e){
			e.preventDefault();
			page.next = $(this).attr("href");
			page.changeURL();
		});
		if(page.isDetail){
			page.resizeDetailPage();
		}
	},
	changeURL: function(){
		if(page.clickFlag){
			page.clickFlag = false;
			window.history.pushState(null, null, page.next);
			page.checkURL();
		}
	},
	afterLoadPage: function(){
		$("body").removeClass(function(index, className) {
			return (className.match(/\bpage_\S+/g) || []).join(' ');
		});
		common.activeScroll();
		common.resize();

		page.resizeDetailPage();
		if(page.isFirst){
			page.isFirst = false;
			if(page.category === "works"){
				wheel.count = 1;
				wheel.move();
				setTimeout(function(){
					if($html.classList.contains("notransition")){
						$html.classList.remove("notransition");
						page.nav();
					}
				},50);
			}
		}
		clearInterval(memberTimer);
		//headerにclass
		$("#global_header").removeClass(function(index, className) {
			return (className.match(/\bheader_\S+/g) || []).join(' ');
		});
		if(page.category === "member"){
			$("#global_header").addClass("header_member");
			wheel.resetTouch();
			memberTimer = setInterval(function(){
				page.resizeMember();
			}, 100);
		}else if(page.category === "company"){
			$("#global_header").addClass("header_company");
			wheel.resetTouch();
		}
		$html.classList.remove("disable_page");
		page.clickFlag = true;
	},
	changePage: function(){
		if(observer === undefined){

		}else{
			observer.disconnect();
		}
		$(".a").removeClass("animate");
		$(".a").removeClass("animated");
		//checkURLのあとはここを通る
		var loadURL = location.pathname,
			$body 	= $("body");
		//console.log(page.category)
		if(page.category === "works"){
			$("#main").addClass("disable");
			$html.classList.remove("disable_page");
			if( $html.classList.contains("detail") ){
				//works内での遷移
				$("#works_detail").addClass("disable");
				setTimeout(function(){
					common.scrollToPosition(0, 600);
					$.get(location.pathname,{},function(data){
						window.scrollTo(0,0);
						setTimeout(function(){
							page.changeContent(data).done(function(){
								$("#works_detail").removeClass("disable");
								page.nav();
								page.resizeDetailPage();
							});
						}, 200);
					});
				},600)
			}else{
				setTimeout(function(){
					$.get(loadURL,{},function(data){
						window.scrollTo(0,0);
						$html.classList.remove("show_nav");
						setTimeout(function(){
							$html.classList.remove("nav");
							page.changeContent(data).done(function(){
								$html.classList.add("detail");
								setTimeout(function(){
									$html.classList.add("show_works_detail");
									page.nav();
								}, 50);
							});
						}, 200);
					});
				}, 600);
			}
		}else if(page.category === "home"){

		}else{
			page.showPage();
		}
	},
	changeContent: function(data){
		var defer = $.Deferred();
		clearInterval(memberTimer);
		var out_html 	= $(data),
			ttl 		= out_html.filter("title")[0].innerHTML,
			$content 	= $("#works_detail #detail_content"),
			content 	= $("#works_detail_inner #detail_load", out_html)[0],
			$imgMain 	= $("#detail_content .img figure", out_html)[0];
		//console.log(ttl)
		document.title = ttl;
		$content.html(content);
		//common.resizeWorksImgPos();
		if( $html.classList.contains("detail") ){
			defer.resolve();
		}else{
			setTimeout(function(){
				page.afterLoadPage();
			},400);
			defer.resolve();
		}
		return defer.promise();
	},
	showPage: function(){
		$html.classList.add("page");
		$("#page").removeClass();
		$("#page").addClass("active_" + page.category);
		setTimeout(function(){
			$("#works_detail .kv_movie").remove();
			window.scrollTo(0,0);
			$html.classList.add("show_page");
			$html.classList.add("can_scroll");
			$html.classList.remove("detail");
			$html.classList.remove("show_works_detail");
			page.afterLoadPage();
		},600);
		
		$html.classList.remove("show_nav");
		setTimeout(function(){
			$html.classList.remove("nav");
		}, 600);
	},
	showHomeAndWork: function(wheelNum){
		$("#global_header").removeClass(function(index, className) {
			return (className.match(/\bheader_\S+/g) || []).join(' ');
		});
		clearInterval(memberTimer);
		wheel.count = workIdx;
		//console.log(wheel.count, workIdx);
		wheel.move();
		common.closeNav();
		common.resize();
		if($html.classList.contains("show_page")){
			$html.classList.remove("show_page");
			works.resize();
			setTimeout(function(){
				removeClasses();
			}, 600);
		}else if($html.classList.contains("show_works_detail")){
			$html.classList.remove("show_works_detail");
			setTimeout(function(){
				removeClasses();
			}, 600);
		}
		setTimeout(function(){
			page.clickFlag = true;
			common.resize();
		},600);
		function removeClasses(){
			$("#works_detail .kv_movie").remove();
			$html.classList.remove("page");
			$html.classList.remove("disable_page");
			$html.classList.remove("detail");
			$html.classList.remove("hide_lead");
			$html.classList.remove("can_scroll");
			$("#main").removeClass("disable");
			page.clickFlag = true;
		}
	},
	backToHome: function(){
		if( $html.classList.contains("show_works_detail") ){
			page.controlGL();
			$html.classList.remove("show_works_detail");
			works.current 	= 0;
			wheel.count 	= 0;
			wheel.move();
			$html.classList.remove("hide_lead");
			$html.classList.remove("can_scroll");
			
			setTimeout(function(){
				$("#works_detail .kv_movie").remove();
				$html.classList.remove("detail");
				$("#main").removeClass("disable");
				page.clickFlag = true;
			}, 600);
		}else{
			page.controlGL();
			works.current 	= 0;
			wheel.count 	= 0;
			wheel.move();
			page.clickFlag = true;
		}
	},
	checkURL : function(){
		let url 	= location.href;
		let prop 	= location.pathname;
		page.path 	= prop;
		let $body 	= $("body"),
			folders = prop.split("/"),
			arg 	= new Object,
			pair 	= location.search.substring(1).split('&'),
			isModal	= false,
			folderLength 	= folders.length-2,
			folderName 		= folders[1];

		page.beforePage = page.category;
		page.category = folderName;
		page.current = prop;
		//console.log(folders, folders.length)
		
		if(!page.isFirst){
			//works詳細の画像を●に戻す
			if(!$html.classList.contains("detail")){
				if( $("#works").hasClass("show_img") ){
					$("#works").removeClass("show_img");
				}
			}
		}
		if(folders.length === 4){
			page.isDetail = true;
		}else{
			page.isDetail = false;
		}
		//console.log(page.category, folders, page.isDetail)
		let cat = page.category;
		if(page.category === ""){
			cat = "home";
		}
		$("body").addClass("page_" + cat);
		$("ul.nav_page li a").removeClass("active");
		$('ul.nav_page li a[data-name=' + cat + ']').addClass("active");
		//console.log("first:", page.isFirst, folders, folders.length);
		if(folders.length > 2){
			//下層ページ ----------------------------------------------------------------
			if(page.isFirst){
				if(page.isDetail){
					page.isFirst = false;
				}else{
					page.afterLoadPage();
				}
				page.controlGL();
			}else{
				//
				if(page.category === "works"){
					page.isPage = "works";
					if(page.isDetail){
						page.closePage();
						EventBus.emit("TRANSITION", {type: "article"});
					}else{
						EventBus.emit("TRANSITION", {type: "works"});
					}
				}else if(page.category === "member" || page.category === "company"){
					page.isPage = page.category;
					if(page.category === "member"){
						let ttl = "MEMBER | " + $("h1 a").attr("title");
						document.title = ttl;
						EventBus.emit("TRANSITION", {type: "member"});
					}else if(page.category === "company"){
						let ttl = "COMPANY | " + $("h1 a").attr("title");
						document.title = ttl;
						EventBus.emit("TRANSITION", {type: "company", w: 0.24});
					}
				}else{
					page.isPage = page.cateogry;
					if(page.isPage === ""){
						page.isPage = "404";
					}
				}
				page.changePage();
			}
		}else{
			//トップページ ----------------------------------------------------------------
			page.isPage 	= "home";
			
			if(page.isFirst){
				page.afterLoadPage();
			}
			if(pair != ""){
				page.closePage();
				for(var i = 0; pair[i]; i++) {
					var kv = pair[i].split('=');
					if(kv[0] === "block" && kv[1] === "works"){
						page.cateogry = "";
						EventBus.emit("TRANSITION", {type: "works"});

						if($html.classList.contains("page") || $html.classList.contains("detail")){
							//下層ページから来た時
							page.showHomeAndWork(1);
						}else{
							common.closeNav();
							//トップページから
							wheel.count = 1;
							wheel.move();
						}
					}
				}
			}else{
				if( $html.classList.contains("show_page") ){
					$html.classList.add("hide_subpage");
					setTimeout(function(){
						$html.classList.remove("show_page");
						wheel.count = 0;
						wheel.current = 0;
						works.resize();
						
						setTimeout(function(){
							wheel.move();
							$html.classList.remove("page");
							$html.classList.remove("disable_page");
							$html.classList.remove("detail");
							$html.classList.remove("hide_lead");
							$html.classList.remove("can_scroll");
							page.clickFlag = true;
							$html.classList.remove("hide_subpage");
						}, 600);
					},600);
				}else{
					page.backToHome();
				}
			}
		}
		//ページロード時
		if( $("body").hasClass("disable") ){
			setTimeout(function(){
				$("body").removeClass("disable");
				$html.classList.remove("notransition");
			},600);
		}
	},
	closePage: function(){
		if( $html.classList.contains("show_page") ){
			$html.classList.remove("show_page");
			setTimeout(function(){
				$html.classList.remove("page");
			},600);
		}
	},
	controlGL: function(){
		let url 	= location.href;
		let prop 	= location.pathname;
		let folders = prop.split("/"),
			arg 	= new Object,
			pair 	= location.search.substring(1).split('&');

		if(page.category === "member"){
			EventBus.emit("TRANSITION", {type: "member"});
		}else if(page.category === "company"){
			EventBus.emit("TRANSITION", {type: "company", w: 0.24});
		}else if(page.category === "home" || page.category === ""){
			$html.classList.remove("can_scroll");
			let ttl = $("h1 a").attr("title");
			document.title = ttl;
			if(pair != ""){
				for(var i = 0; pair[i]; i++) {
					var kv = pair[i].split('=');
					if(kv[0] === "block" && kv[1] === "works"){
						EventBus.emit("TRANSITION", {type: "works"});
					}
				}
			}else{
				EventBus.emit("TRANSITION", {type: "top"});
			}
		}else if(page.category === "works"){
			if(page.isDetail){
				EventBus.emit("TRANSITION", {type: "article"});
			}else{
				EventBus.emit("TRANSITION", {type: "works"});
				let ttl = $("h1 a").attr("title");
				document.title = ttl;
			}
		}
	},
	resizeMember: function(){
		var lh = 0;
		var rh = 0;
		if (window.matchMedia('all and (min-width : 980px)').matches) {
			$(".members ul#list_member li").each(function(i){
				let $this = $(this);
				let _h = $this.outerHeight();
				if(i === 0){
					lh += $this.offset().top;
				}
				if(i%2 === 0){
					lh += _h;
					
				}else{
					let lheight = $(".members ul#list_member li").eq(i - 1).offset().top + $(".members ul#list_member li").eq(i - 1).outerHeight() - $(".members ul#list_member li").eq(i-1).find(".text").outerHeight() - $(".members li").eq(0).offset().top;
					rh = lheight + $this.outerHeight();
					$this.css({
						"top": lheight + "px"
					});
				}
			});
			let _maxH = Math.max(lh, rh);
			$(".members ul#list_member").css({
				"height": _maxH + "px"
			});
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			$(".members ul#list_member").css({
				"height": ""
			});
			$(".members ul#list_member li").css({
				"top": ""
			});
		}
	},
	resizeDetailPage: function(){
		$(".original_iframe").each(function(i){
			let $this = $(this);
			let iframe = $(this).find("iframe");
			let w = iframe.attr("width");
			let h = iframe.attr("height");
			if (window.matchMedia('all and (min-width : 864px)').matches) {
				$this.css("padding-bottom", (h/$("article .article_inner").width()*140) + "%");
			} else if(window.matchMedia('all and (min-width : 0px)').matches) {
				$this.css("padding-bottom", (h/w)*100 + "%");
			}
		});
	}
}