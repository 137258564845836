var isPage = "";
var observer;
var workIdx = 0;
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.nav();
		page.init();
		//common.resize();
		works.init();
		setTimeout(function(){
			common.resize();
		}, 600)
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		$(document).off("scroll touchmove").on("scroll touchmove", function(){
			common.scrl();
		});

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	closeNav: function(){
		page.controlGL();
		if( $html.classList.contains("show_nav") ){
			$html.classList.remove("show_nav");
			setTimeout(function(){
				$html.classList.remove("nav");
			}, 600);
		}
	},
	nav: ()=>{
		$("#btn_menu").off().on("click", function(){
			if(!$html.classList.contains("nav")){
				$html.classList.add("nav");
				EventBus.emit("TRANSITION", {type: "others"});
				setTimeout(function(){
					$html.classList.add("show_nav");
					page.clickFlag = true;
				},50);
			}else{
				common.closeNav();
			}
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		
	//	console.log("resize")
		if(page.category != "company" && page.category != "member"){
			works.resize();
		}
		//console.log("rexize");
		topLayout();
		function topLayout(){
			if(page.category === ""){
				if(isSmallScreen){
					$("body, #main").css({
						"height": $("canvas").height() + "px"
					});
				}else{
					$("#main").css({
						"height": ""
					})
				}
			}else{
				$("#main").css({
					"height": ""
				})
			}
			/**/
			if(isSmallScreen){
				let ftop = $(".scroll").offset().top;
				$(".txt_wrap").css({
					"top": ftop - $(".txt_wrap").outerHeight() - window.innerWidth*0.1533 + "px"
				});
				$("#global_footer").css({
					"top": ftop + 20 + "px",
					"bottom": ""
				});
			}else{
				$("#global_footer").css({
					"top": "",
					"bottom": "0px"
				})
				$("#main").css({
					"height": ""
				})
				$(".txt_wrap").css({
					"top": ""
				});
			}
		}
		
		if(page.category === "company"){
		//	let _w = $(".block_load").width()*
			EventBus.emit("RESIZE", {type: "company", w: 0.24});
		}else if(page.category === "member"){
			var lh = 0;
			var rh = 0;
			if (window.matchMedia('all and (min-width : 980px)').matches) {
				$(".members ul#list_member li").each(function(i){
					let $this = $(this);
					let _h = $this.outerHeight();
					if(i === 0){
						lh += $this.offset().top;
					}
					if(i%2 === 0){
						lh += _h;
						
					}else{
						let lheight = $(".members ul#list_member li").eq(i - 1).offset().top + $(".members ul#list_member li").eq(i - 1).outerHeight() - $(".members ul#list_member li").eq(i-1).find(".text").outerHeight() - $(".members li").eq(0).offset().top;
						rh = lheight + $this.outerHeight();
						$this.css({
							"top": lheight + "px"
						});
					}
				});
				let _maxH = Math.max(lh, rh);
				$(".members ul#list_member").css({
					"height": _maxH + "px"
				});
			} else if(window.matchMedia('all and (min-width : 0px)').matches) {
				$(".members ul#list_member").css({
					"height": ""
				});
				$(".members ul#list_member li").css({
					"top": ""
				});
			}
		}
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		//console.log(topD)
		if( $html.classList.contains("detail") ){
			if(topD >= 100){
				if( !$("#works_detail article").hasClass("active") ){
					$("#works_detail article").addClass("active");
				}
			}else{
				if( $("#works_detail article").hasClass("active") ){
					$("#works_detail article").removeClass("active");
				}
			}
		}
	},
	activeScroll: function(){
		const boxes = document.querySelectorAll(".a");
		const options = {
			root: null, // 今回はビューポートをルート要素とする
			rootMargin: "-33% 0px", // ビューポートの中心を判定基準にする
			threshold: [0, 0.5]	//複数指定可
		};
		//
		observer = new IntersectionObserver(doWhenIntersect, options);
		// それぞれのboxを監視する
		$.each(boxes, function(index, box){
			observer.observe(box);
		});
		function doWhenIntersect(entries) {
			// 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
			for(let i = 0; i < entries.length; i++){
				let e = entries[i];
				if (e.isIntersecting) {
					activateIndex(e.target);
				}
			}
		}
		function activateIndex(element) {
			let idx = $(".a").index(element);
			var $block = $(".a").eq(idx);
			observer.unobserve(element);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				setTimeout(function(){
					$block.addClass("animated");
				},1000);
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	showWorksLoading: function(){
		var defer = $.Deferred();
		if(!$html.classList.contains("w_loading")){
			$html.classList.add("w_loading");
			setTimeout(function(){
				$html.classList.add("w_show_loading");
				$html.classList.add("hide_lead");
				$html.classList.add("can_scroll");
				setTimeout(function(){
					defer.resolve();
				}, 50);
			},50);
		}
		return defer.promise();
	},
	closeWorksLoading: function(){
		var defer = $.Deferred();
		if($html.classList.contains("w_loading")){
			$html.classList.remove("w_show_loading");
			setTimeout(function(){
				$html.classList.add("detail");
				$html.classList.remove("w_loading");
				setTimeout(function(){
					defer.resolve();
				}, 50);
			}, 50);
		}
		return defer.promise();
	},
	showLoading: function(){
		var defer = $.Deferred();
		if(!$html.classList.contains("loading")){
			$html.classList.add("loading");
			setTimeout(function(){
				$html.classList.add("show_loading");
				setTimeout(function(){
					defer.resolve();
				},600);
			},50);
		}
		return defer.promise();
	},
	closeLoading: function(){
		var defer = $.Deferred();
		if($html.classList.contains("loading")){
			$html.classList.remove("show_loading");
			setTimeout(function(){
				$html.classList.remove("loading");
				setTimeout(function(){
					defer.resolve();
				}, 50);
			},600);
		}
		return defer.promise();
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});