
var works = {
	current: 0,
	max: 0,
	w : 0,
	marginR: 0,
	init: function(){
		let $lists = document.querySelectorAll("#list_works li");
		//console.log(lists);
		works.max = $lists.length;
		let worksList = document.querySelectorAll("#list_works ul li a");
		worksList.forEach( ($w, index) => {
			$w.setAttribute("data-num", index);
		});
		document.querySelector(".pager .end").innerHTML = ( '00' + works.max ).slice( -2 );
		//$(".pager .end").html(( '00' + works.max ).slice( -2 ));
		works.resize();
		wheel.init();
	},
	resize: function(){
		$("#list_works li").each(function(i){
			if(isSmallScreen){
				works.w = Math.floor($("#list_works li figure").width());
				works.marginR = Math.floor( (window.innerWidth - works.w*2)/2);
				var posL = $("#works").width()*i;
			}else{
				works.w = Math.floor($("#list_works li").width());
				//console.log(works.w)
				works.marginR = Math.floor( (window.innerWidth - works.w*2)/2);
				var posL = (i*works.w + works.marginR*i) - works.w/2;
			}
			
			$(this).css({
				"left": posL + "px"
			});
		});
		if(isSmallScreen){
			var left = 0;
		}else{
			/*
			var n = works.current;
			if(works.current < 0){
				n = 0;
			}
			var left = n*works.w + (works.w + works.marginR);
			*/
			var left = Math.floor( $("#works_content").width()/2 );
		}
		$("#list_works ul").css({
			"left": left + "px"
		});
		wheel.move();
	}
}

var MOUSE_WHEEL_EVENT = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
var lastTouchX = lastTouchX || 0,
	positionX,cx, topD,
	limit = 80;
var wheelCount = 0,
	uintDelta = 0;
var callback = {
	delta: 0
}
var wdelta = 0;
var delta;
var wheel = {
	count 		: 0,
	max 		: 0,
	canScroll 	: true,
	normalScroll: false,
	canMove : true, //連続してcountを上げ下げ出来ないようにするためのフラグ
	init: function(){
		wheel.max = works.max+1;
		//wheel.count = 0;
		common.resize();
		wheel.nav();
	},
	touchStart: function(e){
		cx 			= 0;
		positionX 	= wheel.getPosition(e);
	},
	touchMove: function(e){
		//console.log("touchmove",$html.classList.contains("can_scroll") )
		if( $html.classList.contains("show_works_detail") || $html.classList.contains("can_scroll") ){
			
		}else{
			e.preventDefault();
		}
		cx 	= positionX - wheel.getPosition(e);
	},
	touchEnd: function(e){
		positionX = 0;
		if(wheel.canScroll){
			if(page.category === "company" || page.category === "member"){
				//会社概要・メンバーのページは移動させない
			}else{
				if($html.classList.contains("nav")){
					cx = 0;
				}
				//console.log(cy,wheel.canScroll)
				if(cx <= -limit){
					wheel.dec();
				}else if(cx > limit){
					wheel.inc();
				}else{
					cx = 0;
					wheel.canScroll = true;
				}
			}
			
		}
	},
	getPosition: function(event){
		return event.touches[0].pageX;
	},
	touchCancel: function(e){
		cx = 0;
		wheel.canScroll = true;
	},
	resetTouch: function(){
		/*
		document.removeEventListener('touchstart', function(e){
			wheel.touchStart(e);
		},{ passive: false});
		document.removeEventListener('touchmove', function(e){
			wheel.touchMove(e);
		},{ passive: false});
		document.removeEventListener('touchend', function(e){
			wheel.touchEnd(e);
		},{ passive: false});
		document.removeEventListener('touchcancel', function(e){
			wheel.touchCancel(e);
		},{ passive: false});
		*/
	},
	unbindNav: function(){
		//console.log("unbind");
		$(document).off(MOUSE_WHEEL_EVENT);
	},
	nav: function(){
		//console.log("bind")
		let $html = document.documentElement;
		var isFired = false;
		if(isSP || isIPad){
			document.addEventListener('touchstart', function(e){
				wheel.touchStart(e);
			},{ passive: false});
			document.addEventListener('touchmove', function(e){
				wheel.touchMove(e);
			},{ passive: false});
			document.addEventListener('touchend', function(e){
				wheel.touchEnd(e);
			},{ passive: false});
			document.addEventListener('touchcancel', function(e){
				wheel.touchCancel(e);
			},{ passive: false});
		}else{
			callback = function (delta) {
				if(delta >= 1) {
					delta = 0;
					setTimeout(function(){
						wheel.dec();
					},100);
				}else if(delta < 0){
					delta = 0;
					setTimeout(function(){
						wheel.inc();
					},100);
				}else{
					wheel.canScroll = true;
				}
			};
			wheel.canScroll = true;
			
			// キャッシュ
			callback.delta 		= 0;
			callback.timeStamp 	= Date.now();
			/*$(document).off(MOUSE_WHEEL_EVENT).on(MOUSE_WHEEL_EVENT, function(e){
				getdelta(e);
			});*/
			/*$(document).off(MOUSE_WHEEL_EVENT).on(MOUSE_WHEEL_EVENT, function(e){
				getdelta(e);
			})*/
			document.addEventListener('wheel', function(event) {
				//console.log(`Wheel delta: ${event.deltaY}`);
				//console.log("wheel", event.deltaY, $html);
				if( $html.classList.contains("show_works_detail") || $html.classList.contains("show_page") ){
					//console.log("is detail page");
					return;
				}else{
					getdelta(event);
					// deltaY は、ホイールの垂直方向のスクロール量を示します
					// deltaX と deltaZ も利用可能です
					event.preventDefault();
					// イベントのデフォルトの動作を防止（ページスクロールなど）
					//event.preventDefault();
				}
				
			});
			
			var interval = 100;
			var last_time = 0;
			uintDelta = 0;
			callback.delta = 0;
			delta = 0;
			
			function getdelta(e){
				if(page.category != "" || page.isDetail){
					return false
				}
				if(wheel.normalScroll){
					wheel.canScroll = true;
				}else{
					var now = new Date().getTime();
					var diff = now - callback.timeStamp;
					//console.log(e);
					let pixels;
					switch (event.deltaMode) {
						case event.DOM_DELTA_PIXEL:
							pixels = event.deltaY;
							break;
						case event.DOM_DELTA_LINE:
							pixels = event.deltaY * 24; // 仮に1行を24ピクセルと仮定
							break;
						case event.DOM_DELTA_PAGE:
							pixels = event.deltaY * window.innerHeight; // ビューポートの高さを使用
							break;
					}
					//console.log(pixels);
					
					
					/*
					if(!isWindows){
						delta = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.originalEvent.deltaY : -e.originalEvent.deltaY;
					}else{
						delta = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.originalEvent.wheelDelta : -(e.originalEvent.delta);
					}
					delta = e.originalEvent.deltaY*-1;
					*/
					delta = pixels*-1;
					if (!delta) { return;}
					uintDelta = Math.abs(delta);
					
					if (uintDelta - callback.delta > 0) {
						//増加
						if (diff > interval && !isFired){
							callback(delta);
							isFired = true;
						}
						callback.timeStamp = now;
					}else{
						//減退
						isFired = false;
					}
					callback.delta = uintDelta;
					
				}
			}
		}
	},
	inc: function(){
		if($html.classList.contains("detail")){ return false;}
		if($html.classList.contains("nav")){ return false;}
		if($html.classList.contains("page")){ return false;}
		if(wheel.canScroll){ 
			wheel.canScroll = false;
			wheel.count++;
			if(wheel.count >= wheel.max){
				wheel.count = wheel.max-1;
				wheel.canScroll = true;
			}else{
				if(wheel.count > 1){
					$("#list_works").addClass("disable");
				}
				wheel.move();
			}
		}
	},
	dec: function(){
		if($html.classList.contains("detail")){ return false;}
		if($html.classList.contains("nav")){ return false;}
		if($html.classList.contains("page")){ return false;}
		if(wheel.canScroll){ 
			wheel.canScroll = false;
			wheel.count--;
			if(wheel.count < 0){
				wheel.count = 0;
				wheel.canScroll = true;
			}else{
				$("#list_works").addClass("disable");
				wheel.move();
			}
		}
	},
	move: function(){
		//console.log("move")
		wheel.unbindNav();
		if(wheel.count === 0){
			if(!page.isFirst && page.category === "" || page.category === "home"){
				EventBus.emit("TRANSITION", {type: "top"});
				window.history.pushState(null, null, "/");
			}
		}else{
			$("#main").removeClass("disable");
			if(!page.isDetail){
				//console.log("works")
				EventBus.emit("TRANSITION", {type: "works"});
				setTimeout(function(){
					window.history.pushState(null, null, "/?block=works");
				}, 300);
			}
		}
		if(!page.isDetail){
			callback.delta = 0;
			if(wheel.count > 0){
				if(!$html.classList.contains("isWorks")){
					$html.classList.add("isWorks");
				}
			}else{
				if($html.classList.contains("isWorks")){
					$html.classList.remove("isWorks");
				}
			}
			$("html").removeClass(function(index, className) {
				return (className.match(/\bactive\S+/g) || []).join(' ');
			});
			$html.classList.add("active" + wheel.count);
			works.current = wheel.count-1;
			//console.log(isSmallScreen, $("#list_works").width() )
			if(isSmallScreen){
				var left = ($("#list_works").width())*works.current*-1;
			}else{
				var left = (window.innerWidth/2)*works.current*-1;
			}
			$("#list_works ul").css({
				"transform": "translate3d(" + left + "px, 0,0)"
			});
			$("#list_works ul li").removeClass("active");

			if(works.current < 0){
				$("#list_works ul li").eq(0).addClass("active");
				setTimeout(function(){
					checkDelta();
				}, 800);
			}else{
				$("#list_works ul li").eq(works.current).addClass("active");
				setTimeout(function(){
					let title = $("#list_works ul li").eq(works.current).find("a").attr("data-title");
					$("#list_works .current_select h3 span").html(title);
					$("#list_works").removeClass("disable");
					checkDelta();
				}, 800);
			}
			let per = Math.floor(wheel.count/(wheel.max-1)*100);
			$(".pager .current").html( ( '00' + wheel.count ).slice( -2 ) )
			$(".pager .progress .bar").css({
				"width": per + "%"
			});
			page.clickFlag = true;
			function checkDelta(){
				//console.log("checkDelta:", delta);
				wheel.canScroll = true;
				if(isWindows){
					setTimeout(function(){
						wheel.nav();
					}, 400);
				}else{
					wheel.nav();
				}
			}
		}
	}
}