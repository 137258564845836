const 	ua 		= window.navigator.userAgent.toLowerCase(),
		appver 	= window.navigator.appVersion.toLowerCase();
let isLegacy 	= appver.indexOf("msie 8.0") > -1 || appver.indexOf("msie 9.0") > -1 || appver.indexOf("msie 10.0") > -1,
	isIE 		= ua.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/),
	isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1,
	isAndroid 	= navigator.userAgent.match(/Android/i),
	isIPhone 	= navigator.userAgent.match(/iPhone/i),
	isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i),
	isIPad 		= navigator.userAgent.match(/iPad/i),
	isFF 		= ua.indexOf("firefox")!== -1,
	isEdge 		= ua.indexOf("applewebkit") > -1 && ua.indexOf("edge") > -1,
	isMobile	= true,
	isSmallScreen = false,
	isPC = true,
	isWindows = false;

if(isAndroid || isIPhone || isIPad){
	isPC = false;
}else{
	isPC = true;
}
var $html = document.documentElement;
if( isIpad() ){
	isIPad = true;
	document.querySelector("meta[name='viewport']").setAttribute("content", "viewportContent=width=1136px,initial-scale=.7,user-scalable=no,shrink-to-fit=yes");
	$html.classList.add("iPad");
}
/*
if (navigator.platform == "MacIntel" &&　navigator.userAgent.indexOf("Safari") != -1 &&　navigator.userAgent.indexOf("Chrome") == -1) {
	if (navigator.standalone !== undefined) {
		isIPad = true;
		document.querySelector("meta[name='viewport']").setAttribute("content", "viewportContent=width=1136px,initial-scale=.7,user-scalable=no,shrink-to-fit=yes");
		$html.classList.add("iPad");
	} else {
	// macOS Safari
	}
}
*/
function isIpad() {
	const userAgent = navigator.userAgent.toLowerCase();
	const isIOS = /ipad|iphone|ipod/.test(userAgent);
	const isMacSafari = userAgent.includes('macintosh') && 'ontouchend' in document;

	return isIOS || isMacSafari;
}
//win or mac
if( navigator.userAgent.indexOf("Win") !== -1 ){
	isWindows = true;
	let ary = ["os_windows"];
	if(isFF){
		ary.push("firefox");
	}else{
		if(isIE){
			if(isIE11){
				ary.push("ie11");
			}
		}else if(isEdge){
			ary.push("edge");
		}else{
		}
	}
	addClassToBody(ary);
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	let ary = ["os_mac"];
	if(isFF){
		ary.push("firefox");
	}else{
		if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1){
			ary.push("safari");
		}else{
			ary = ["os_mac"];
		}
	}
	addClassToBody(ary);
}
function addClassToBody(ary){
	for(let i = 0; i < ary.length; i++){
		$html.classList.add(ary[i]);
	}
}
var mouse = [];